"use client";

import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import airportDetails from "@/dataSet/airportDetails";
import { useRouter } from "next/navigation";
import toast from "react-hot-toast";
import { reset } from "@/redux/flightsSlice";
import { useDispatch } from "react-redux";
import { MdOutlineLocalAirport } from "react-icons/md";
import { FaCaretUp } from "react-icons/fa";
import { fetchItinerary } from "@/redux/itinerarySlice";

const Page = ({ fromRatingPage, travelerCounts }) => {
  const searchResultPage = window.location.pathname === "/searchResult";
  const dispatch = useDispatch();
  const [inputGroups, setInputGroups] = useState([{ id: 1 }, { id: 2 }]);
  const [departureAirports, setDepartureAirports] = useState([
    { name: "", code: "" },
    { name: "", code: "" },
  ]);
  const [arrivalAirports, setArrivalAirports] = useState([
    { name: "", code: "" },
    { name: "", code: "" },
  ]);
  const [departureDates, setDepartureDates] = useState(["", ""]);
  const [dropdownData, setDropdownData] = useState({ from: [], to: [] });
  const [focus, setFocus] = useState({ from: null, to: null });
  const dropdownRef = useRef(null);
  const router = useRouter();

  // Updated customIndexList with the new structure
  const customIndexList = [
    { code: "DAC", index: 1 },
    { code: "CGP", index: 2 },
    { code: "CXB", index: 3 },
    { code: "SPD", index: 4 },
    { code: "ZYL", index: 5 },
    { code: "RJH", index: 6 },
    { code: "BZL", index: 7 },
    { code: "DXB", index: 8 },
    { code: "JFK", index: 9 },
    { code: "SIN", index: 10 },
    // Add more airports with their custom indices
  ];

  // Updated customIndexedAirports using the customIndexList
  const customIndexedAirports = customIndexList
    .map(({ code, index }) => {
      const airport = airportDetails.find((a) => a.airportCode === code);
      return airport ? { ...airport, customIndex: index } : null;
    })
    .filter((airport) => airport !== null);

  useEffect(() => {
    localStorage.setItem("departureAirports", JSON.stringify(["", ""]));
    localStorage.setItem("arrivalAirports", JSON.stringify(["", ""]));
    localStorage.setItem("departureDates", JSON.stringify(["", ""]));

    const storedDepartureAirports = JSON.parse(
      localStorage.getItem("departureAirports")
    ) || ["", ""];
    const storedArrivalAirports = JSON.parse(
      localStorage.getItem("arrivalAirports")
    ) || ["", ""];
    const storedDepartureDates = JSON.parse(
      localStorage.getItem("departureDates")
    ) || ["", ""];

    const loadedDepartureAirports = storedDepartureAirports.map((code) => ({
      name: "",
      code,
    }));
    const loadedArrivalAirports = storedArrivalAirports.map((code) => ({
      name: "",
      code,
    }));

    setDepartureAirports(loadedDepartureAirports);
    setArrivalAirports(loadedArrivalAirports);
    setDepartureDates(storedDepartureDates);
  }, []);

  useEffect(() => {
    const storedDepartureAirports = departureAirports.map(
      (airport) => airport.code
    );
    localStorage.setItem(
      "departureAirports",
      JSON.stringify(storedDepartureAirports)
    );
  }, [departureAirports]);

  useEffect(() => {
    const storedArrivalAirports = arrivalAirports.map(
      (airport) => airport.code
    );
    localStorage.setItem(
      "arrivalAirports",
      JSON.stringify(storedArrivalAirports)
    );
  }, [arrivalAirports]);

  useEffect(() => {
    localStorage.setItem("departureDates", JSON.stringify(departureDates));
  }, [departureDates]);

  const debouncedFetchFromData = useCallback(
    _.debounce((value) => {
      filterData(value, "from");
    }, 300),
    []
  );

  const debouncedFetchToData = useCallback(
    _.debounce((value) => {
      filterData(value, "to");
    }, 300),
    []
  );

  const filterData = (value, type) => {
    if (!value) {
      setDropdownData((prevState) => ({
        ...prevState,
        [type]: customIndexedAirports,
      }));
      return;
    }

    const filteredList = airportDetails.filter((airport) => {
      const valueLowerCase = value.toLowerCase();
      const nameLowerCase = airport.airportName.toLowerCase();
      const codeLowerCase = airport.airportCode.toLowerCase();
      const cityLowerCase = airport.cityName.toLowerCase();
      const countryLowerCase = airport.countryName.toLowerCase();

      return (
        nameLowerCase.includes(valueLowerCase) ||
        codeLowerCase.includes(valueLowerCase) ||
        cityLowerCase.includes(valueLowerCase) ||
        countryLowerCase.includes(valueLowerCase)
      );
    });

    setDropdownData((prevState) => ({ ...prevState, [type]: filteredList }));
  };

  const handleDepartureCityChange = (index, value) => {
    const updatedAirports = [...departureAirports];
    updatedAirports[index] = { name: value, code: "" };
    setDepartureAirports(updatedAirports);
    debouncedFetchFromData(value);
  };

  const handleArrivalCityChange = (index, value) => {
    const updatedAirports = [...arrivalAirports];
    updatedAirports[index] = { name: value, code: "" };
    setArrivalAirports(updatedAirports);
    debouncedFetchToData(value);
  };

  const handleDepartureDateChange = (index, value) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format
    if (value >= today) {
      // Only accept dates from today onwards
      const updatedDates = [...departureDates];
      updatedDates[index] = value;
      setDepartureDates(updatedDates);
    }
  };

  const handleFromSelect = (index, airport) => {
    const updatedAirports = [...departureAirports];
    updatedAirports[index] = {
      name: airport.airportName,
      code: airport.airportCode,
    };
    setDepartureAirports(updatedAirports);
    setFocus((prevState) => ({ ...prevState, from: null }));
  };

  const handleToSelect = (index, airport) => {
    const updatedAirports = [...arrivalAirports];
    updatedAirports[index] = {
      name: airport.airportName,
      code: airport.airportCode,
    };
    setArrivalAirports(updatedAirports);
    setFocus((prevState) => ({ ...prevState, to: null }));
  };

  const handleAddInputGroup = () => {
    const newId =
      inputGroups.length > 0 ? inputGroups[inputGroups.length - 1].id + 1 : 1;
    setInputGroups([...inputGroups, { id: newId }]);
    setDepartureAirports([...departureAirports, { name: "", code: "" }]);
    setArrivalAirports([...arrivalAirports, { name: "", code: "" }]);
    setDepartureDates([...departureDates, ""]);
  };

  const handleDeleteInputGroup = (id) => {
    if (inputGroups.length > 2) {
      const groupIndex = inputGroups.findIndex((group) => group.id === id);

      const updatedDepartureAirports = [...departureAirports];
      const updatedArrivalAirports = [...arrivalAirports];
      const updatedDepartureDates = [...departureDates];

      updatedDepartureAirports.splice(groupIndex, 1);
      updatedArrivalAirports.splice(groupIndex, 1);
      updatedDepartureDates.splice(groupIndex, 1);

      setDepartureAirports(updatedDepartureAirports);
      setArrivalAirports(updatedArrivalAirports);
      setDepartureDates(updatedDepartureDates);

      const updatedInputGroups = inputGroups.filter((group) => group.id !== id);
      setInputGroups(updatedInputGroups);
    }
  };

  const handleToggleFocus = (type, index) => {
    if (focus[type] === index) {
      // If already focused, toggle off
      setFocus({ from: null, to: null });
    } else {
      // Otherwise, focus on the new field and load dropdown data
      setFocus({
        from: type === "from" ? index : null,
        to: type === "to" ? index : null,
      });
      setDropdownData((prevState) => ({
        ...prevState,
        [type]: customIndexedAirports,
      }));
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setFocus({ from: null, to: null });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isDisabled, setIsDisabled] = useState(false);

  const departureAirportsArray = departureAirports.map((air) => air.code);
  const arrivalAirportsArray = arrivalAirports.map((air) => air.code);

  // console.log(departureAirportsArray, "departureAirportsArray");

  const handleSearchFlight = (e) => {
    e.preventDefault();
    dispatch(reset());
    if (departureAirports[0].code === arrivalAirports[0].code) {
      return toast.error("Please select different locations.");
    }

    let query = `/searchResult?origin=${departureAirportsArray}&destination=${arrivalAirportsArray}&startDate=${departureDates}`;

    if (travelerCounts && travelerCounts.adults > 1) {
      query += `&adults=${travelerCounts.adults}`;
    }
    if (travelerCounts && travelerCounts.children > 0) {
      query += `&children=${travelerCounts.children}`;
    }
    if (travelerCounts && travelerCounts.infants > 0) {
      query += `&infants=${travelerCounts.infants}`;
    }
    if (travelerCounts && travelerCounts.kids > 0) {
      query += `&kids=${travelerCounts.kids}`;
    }
    // Check if any of the required state variables are empty
    if (
      departureAirports.some((airport) => airport.name === "") ||
      arrivalAirports.some((airport) => airport.name === "") ||
      departureDates.some((date) => date === "")
    ) {
      toast.error("Please fill in all fields.");
    } else {
      localStorage.setItem("show", "true");
      localStorage.setItem("type", "multi city");
      // setIsDisabled(true);
      // Navigate to the search result page
      // console.log("search clicked");
      // if (window.location.pathname === "/searchResult") {
      //   window.location.reload();

      router.push(query);
      dispatch(fetchItinerary());
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <div className="py-1 text-black">
      <form
        onSubmit={handleSearchFlight}
        className={`${
          fromRatingPage || searchResultPage
            ? "hidden md:block "
            : "rounded hidden md:block"
        } ${searchResultPage && "lg:w-[65%]"} py-1`}
      >
        {inputGroups.map((group, index) => (
          <div
            key={group.id}
            className={`${
              fromRatingPage ? "grid gap-2" : "flex flex-wrap"
            } mb-4 relative gap-x-1`}
          >
            <div className="flex-1 relative mb-0 sm:mb-0">
              <input
                type="text"
                required
                className={`mr-2 w-full p-2 border border-gray-300 rounded focus:outline-none text-sm focus:ring-[1.5px] focus:ring-green-600 ${
                  searchResultPage ? "h-[40px] text-sm" : "h-[50px]"
                }`}
                placeholder={`Departure`}
                onFocus={handleFocus}
                value={departureAirports[index].name || ""}
                onChange={(e) =>
                  handleDepartureCityChange(index, e.target.value)
                }
                onClick={() => handleToggleFocus("from", index)}
              />
              {focus.from === index && dropdownData.from.length > 0 && (
                <div ref={dropdownRef} className="absolute top-full">
                  <div className="relative">
                    <ul className="min-w-max mt-3 bg-white shadow-md overflow-hidden rounded-[4px] relative left-0 z-10 ">
                      {dropdownData.from.slice(0, 11).map((airport) => (
                        <div
                          key={airport.airportCode}
                          className="cursor-default"
                          onMouseDown={() => handleFromSelect(index, airport)}
                        >
                          <div className="bg-white py-1 px-2 pr-4 border-b overflow-hidden hover:bg-gray-100 duration-100">
                            <div className="flex justify-between">
                              <div className="flex gap-2 items-center">
                                <MdOutlineLocalAirport className="text-gray-400" />
                                <p className="text-sm text-left font- text-black py-2">
                                  {airport.airportName}, {airport.cityName}{" "}
                                  <span className="text-gray-500 ml-1">
                                    {airport.airportCode}
                                  </span>
                                </p>
                              </div>
                              {/* Display the custom index */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </ul>
                    <FaCaretUp
                      size={20}
                      className="absolute -top-[12px] left-3 text-white"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex-1 relative mb-2sm:mb-0">
              <input
                type="text"
                required
                className={`mr-2 w-full p-2 border border-gray-300 rounded focus:outline-none text-sm focus:ring-[1.5px] focus:ring-green-600 ${
                  searchResultPage ? "h-[40px] text-sm" : "h-[50px]"
                }`}
                placeholder={`Arrival`}
                value={arrivalAirports[index].name || ""}
                onChange={(e) => handleArrivalCityChange(index, e.target.value)}
                onClick={() => handleToggleFocus("to", index)}
                onFocus={handleFocus}
              />
              {focus.to === index && dropdownData.to.length > 0 && (
                <div ref={dropdownRef} className="absolute top-full">
                  <div className="relative">
                    <ul className="min-w-max mt-3 bg-white shadow-md overflow-hidden rounded-[4px] relative left-0 z-10 ">
                      {dropdownData.to.slice(0, 11).map((airport) => (
                        <div
                          key={airport.airportCode}
                          className="cursor-default"
                          onMouseDown={() => handleToSelect(index, airport)}
                        >
                          <div className="bg-white py-1 px-2 pr-4 border-b overflow-hidden hover:bg-gray-100 duration-100">
                            <div className="flex justify-between">
                              <div className="flex gap-2 items-center">
                                <MdOutlineLocalAirport className="text-gray-400" />
                                <p className="text-sm text-left font- text-black py-2">
                                  {airport.airportName}, {airport.cityName}{" "}
                                  <span className="text-gray-500 ml-1">
                                    {airport.airportCode}
                                  </span>
                                </p>
                              </div>
                              {/* Display the custom index */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </ul>
                    <FaCaretUp
                      size={20}
                      className="absolute -top-[12px] left-3 text-white"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex-1">
              <input
                type="date"
                required
                min={new Date().toISOString().split("T")[0]} // Disable past dates
                className={`date-input !text-sm mr-2 w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-[1.5px] focus:ring-green-600 ${
                  searchResultPage ? "h-[40px] " : "h-[50px]"
                }`}
                value={departureDates[index] || ""}
                onChange={(e) =>
                  handleDepartureDateChange(index, e.target.value)
                }
                onClick={(e) => e.target.showPicker()}
              />
            </div>
            {inputGroups.length > 2 && (
              <div className="flex-none ml-2">
                <button
                  className="px-2 py-2 bg-red-700 !text-[15px] md:text-base text-white rounded h-[50px]"
                  onClick={() => handleDeleteInputGroup(group.id)}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        ))}
        <div className="my-4">
          <button
            className="bg-green-700 duration-300 text-white !text-[15px] md:text-base px-4 py-2 rounded hover:bg-green-900 ms-auto flex"
            onClick={handleAddInputGroup}
          >
            Add More
          </button>
        </div>
        <button
          type="submit"
          disabled={isDisabled}
          className="bg-[#00703E] hover:bg-[#185f3f] text-[15px] duration-300 disabled:bg-gray-500 w-[150px] h-[50px] text-white rounded"
        >
          Search Flights
        </button>
      </form>

      <div className="md:hidden">
        <form
          onSubmit={handleSearchFlight}
          className={`${fromRatingPage || searchResultPage ? "" : "rounded"} `}
        >
          {inputGroups.map((group, index) => (
            <div
              key={group.id}
              className={`${
                fromRatingPage ? "grid gap-2" : "flex flex-wrap"
              } mb-4 relative gap-x-1`}
            >
              <div className="flex-1 relative mb-0 sm:mb-0">
                <input
                  type="text"
                  required
                  className={`mr-2 w-full p-2 h-[40px] border text-sm rounded focus:outline-none focus:ring-1 focus:ring-green-600 ${
                    searchResultPage ? "h-[40px] text-sm" : ""
                  }`}
                  placeholder={`Departure`}
                  onFocus={handleFocus}
                  value={departureAirports[index].name || ""}
                  onChange={(e) =>
                    handleDepartureCityChange(index, e.target.value)
                  }
                  onClick={() => handleToggleFocus("from", index)}
                />
                {focus.from === index && dropdownData.from.length > 0 && (
                  <div ref={dropdownRef} className="absolute top-full">
                    <div className="relative">
                      <ul className="min-w-max mt-3 bg-white shadow-md border overflow-hidden rounded-[4px] relative left-0 z-10 ">
                        {dropdownData.from.map((airport) => (
                          <div
                            key={airport.airportCode}
                            className="bg-blue-50 cursor-pointer hover:bg-blue-100"
                            onMouseDown={() => handleFromSelect(index, airport)}
                          >
                            <div className="bg-white py-1 px-2 pr-3 border-b overflow-hidden hover:bg-gray-100 duration-100">
                              <div className="flex justify-between">
                                <div className="flex gap-2 items-center w-full">
                                  <MdOutlineLocalAirport className="text-gray-400" />
                                  <div className="flex text-xs  justify-between items-center w-full">
                                    <p className="text-left text-black py-2">
                                      {airport.airportName.length > 13
                                        ? airport.airportName.slice(0, 14) +
                                          "..."
                                        : airport.airportName}
                                      , {airport.cityName}{" "}
                                    </p>
                                    <span className="text-gray-500 ml-1">
                                      {airport.airportCode}
                                    </span>
                                  </div>
                                </div>
                                {/* Display the custom index */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                      <FaCaretUp
                        size={21}
                        className="absolute -top-[13px] left-3 text-gray-300 drop-shadow-xl"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-1 relative mb-2sm:mb-0">
                <input
                  type="text"
                  required
                  className={`mr-2 w-full p-2 h-[40px] border text-sm rounded focus:outline-none focus:ring-1 focus:ring-green-600 ${
                    searchResultPage ? "h-[40px] text-sm" : ""
                  }`}
                  placeholder={`Arrival`}
                  value={arrivalAirports[index].name || ""}
                  onChange={(e) =>
                    handleArrivalCityChange(index, e.target.value)
                  }
                  onClick={() => handleToggleFocus("to", index)}
                  onFocus={handleFocus}
                />
                {focus.to === index && dropdownData.to.length > 0 && (
                  <div ref={dropdownRef} className="absolute top-full ">
                    <div className="relative">
                      <ul className="min-w-max mt-3 bg-white shadow-md border overflow-hidden rounded-[4px] relative left-0 z-10">
                        {dropdownData.to.map((airport) => (
                          <div
                            key={airport.airportCode}
                            className="bg-blue-50 cursor-pointer hover:bg-blue-100"
                            onMouseDown={() => handleToSelect(index, airport)}
                          >
                            <div className="bg-white py-1 px-2 pr-3 border-b overflow-hidden hover:bg-gray-100 duration-100">
                              <div className="flex justify-between">
                                <div className="flex gap-2 items-center w-full">
                                  <MdOutlineLocalAirport className="text-gray-400" />
                                  <div className="flex text-xs  justify-between items-center w-full">
                                    <p className="text-left text-black py-2">
                                      {airport.airportName.length > 13
                                        ? airport.airportName.slice(0, 14) +
                                          "..."
                                        : airport.airportName}
                                      , {airport.cityName}{" "}
                                    </p>
                                    <span className="text-gray-500 ml-1">
                                      {airport.airportCode}
                                    </span>
                                  </div>
                                </div>
                                {/* Display the custom index */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                      <FaCaretUp
                        size={21}
                        className="absolute -top-[13px] left-3 text-gray-300 drop-shadow-xl"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-1">
                <input
                  type="date"
                  required
                  min={new Date().toISOString().split("T")[0]} // Disable past dates
                  className={`mr-2 w-full p-2 h-[40px] border rounded focus:outline-none date-input !text-sm focus:ring-1 focus:ring-green-600 ${
                    searchResultPage ? "h-[40px]" : ""
                  }`}
                  value={departureDates[index] || ""}
                  onChange={(e) =>
                    handleDepartureDateChange(index, e.target.value)
                  }
                  onClick={(e) => e.target.showPicker()}
                />
              </div>
              {inputGroups.length > 2 && (
                <div className="flex-none ml-2">
                  <button
                    className="px-1 py-2 bg-red-700 text-sm md:text-base text-white rounded h-[40px]"
                    onClick={() => handleDeleteInputGroup(group.id)}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          ))}
          <div className="my-4">
            <button
              className="bg-green-700 text-white text-sm md:text-base px-4 py-2 rounded hover:bg-green-900 ms-auto flex"
              onClick={handleAddInputGroup}
            >
              Add More
            </button>
          </div>
          <button
            type="submit"
            // disabled={isDisabled}
            className="bg-green-800 w-full md:w-[150px] h-[40px] text-sm text-white rounded mt-2 md:mt-0"
          >
            Search Flights
          </button>
        </form>
      </div>
    </div>
  );
};

export default Page;
